import jQuery from 'jquery'

var url = process.env.FORM_URL;
var urlHome = process.env.HOME_FORM_URL;

var sucessFormMessage = 'Thanks for your request. We will respond within 24 hours.';
var sucessFormMessageDE = 'Vielen Dank für Ihre Nachricht.';
jQuery.support.cors = true;
jQuery('document').ready(function () {

    //$("body").on("submit", "#front_featured-project_form_get-in-touch", function(e) {
    jQuery("body").on("submit", "#landing_get_in_touch", function (e) {
        let $this = jQuery(this);

        jQuery("#preloader").fadeIn();
        jQuery("#home_message .form-input-y ul").remove();
        jQuery.ajax({
            type: "POST",
            dataType: 'json',
            url: url,
            data: jQuery(this).serialize(),
        }).done(function (data) {
            jQuery("#preloader").fadeOut();
            jQuery("#home_message").hide();
            jQuery(".zf-landing-form_result").html(sucessFormMessage).removeClass('error').show();
        }).fail(function (jqXHR, textStatus, error) {
            jQuery("#preloader").fadeOut();
            if (jqXHR.responseText.length) {
                let errors = jQuery.parseJSON(jqXHR.responseText);

                $this.find('.js-form-errors').remove();

                jQuery.each(errors, function (index, element) {
                    if ('#' === index) {
                        $this.find(".form-submit-row").prepend("<ul class='js-form-errors'><li>" + element + "</li></ul>")
                    } else {
                        jQuery("#home_message_" + index).parents(".form-input-y").after("<ul class='js-form-errors'>" +
                            "<li>" + element.join('</li><li>') + "</li>" +
                            "</ul>");
                    }
                });

            } else {
                jQuery(".zf-landing-form_result").html(error).addClass('error').show();
            }
        });

        e.preventDefault();
    });

    jQuery("body").on("submit", "#de-form", function (e) {
        let $this = jQuery(this);
        jQuery("#preloader").fadeIn();
        jQuery("#de-form .input-row ul").remove();
        jQuery.ajax({
            type: "POST",
            dataType: 'json',
            crossDomain: true,
            url: urlHome,
            data: jQuery(this).serialize()
        }).done(function (data) {
            jQuery("#preloader").fadeOut();
            jQuery("#de-form").hide();
            jQuery(".form_result").html(sucessFormMessageDE).removeClass('error').show();
        }).fail(function (jqXHR, textStatus, error) {
            jQuery("#preloader").fadeOut();
            if (jqXHR.responseText && jqXHR.responseText.length) {
                let errors = jQuery.parseJSON(jqXHR.responseText);

                $this.find('.js-form-errors').remove();

                jQuery.each(errors, function (index, element) {
                    jQuery("#home_message_" + index).parents(".input-row").after("<ul class='js-form-errors'>" +
                        "<li>" + element.join('</li><li>') + "</li>" +
                        "</ul>");
                });

            } else {
                jQuery(".form_result").html(error).addClass('error').show();
            }
        });

        e.preventDefault();
    });


    jQuery('.trigger').on('click', function (e) {
        e.preventDefault();
        jQuery(this).fadeOut(400, function () {
            jQuery("#home_message").show();
            jQuery('#modal').slideToggle();

            var offset = jQuery('#modal').offset();
            var body = jQuery("html, body");

            body.stop().animate({scrollTop: offset.top}, 500, 'swing', function () {
            });
        });

    });

    function initFormAnimation() {
        var form = jQuery('.call-form-y')

        form.each(function () {
            var form = jQuery(this),
                fileds = form.find('input[placeholder],textarea'),
                firstField = fileds.first(),
                isFilled = false;

            firstField.parent().addClass('has-anim');

            jQuery(fileds).each(function () {
                jQuery(this).on('focus', function () {
                    form.addClass('focused');
                });
                jQuery(this).on('blur', function () {
                    isFilled = false;
                    jQuery(fileds).each(function () {
                        if (this.value) {
                            isFilled = true;
                        }
                    });
                    if (!isFilled) {
                        form.removeClass('focused');
                    }
                });
            });
        });
    }

    initFormAnimation();
});
